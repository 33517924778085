import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import apiPro from "../../api/apiPro";
import LinearProgress from "@mui/material/LinearProgress";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import swal from "sweetalert";
import { useParams } from "react-router-dom";

function AddPdf({
  setUpdateLessonsAfterAdd,
  updateLessonData,
  setIsUpdatePdf,
  setUpdateLessonData,
  isUpdatePdf,
}) {
  const { id } = useParams();
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const clearFields = () => {
    setTitle("");
    setSelectedFile(null);
    setIsUpdatePdf(false);
    setUpdateLessonData(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpen(false);
    if (isUpdatePdf) {
      clearFields();
    }
  };
  const handleAdd = async () => {
    setLoadingAdd(true);
    try {
      const formData = new FormData();
      console.log(title);
      formData.append("title", title);
      formData.append("pdf", selectedFile);
      console.log(formData);
      await apiPro.post(`/months/${id}/lessons/pdf`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      swal("تم اضافة الملف بنجاح", {
        icon: "success",
        timer: 2000,
        buttons: false,
      });
      setUpdateLessonsAfterAdd((prev) => prev + 1);
      setTitle("");
      setSelectedFile(null);
      setLoadingAdd(false);
      setOpen(false);
    } catch (error) {
      swal("حدث خطأ ما يرجى المحاولة مرة اخرى", {
        icon: "error",
      });
      setLoadingAdd(false);
    }
  };
  const handleUpdate = async () => {};
  useEffect(() => {
    if (isUpdatePdf) {
      setOpen(true);
      setTitle(updateLessonData.title);
      setSelectedFile("updateLessonData.pdfUrl");
    }
  }, [isUpdatePdf, updateLessonData]);
  return (
    <Box>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        color="success"
        endIcon={<AddIcon />}
      >
        اضافة ملف
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>{isUpdatePdf ? "تعديل الملف" : "اضافة ملف"}</DialogTitle>
        {loadingAdd && <LinearProgress />}
        <DialogContent dividers>
          <TextField
            fullWidth
            label="عنوان الملف"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin="normal"
            variant="outlined"
          />
          <Box mt={1}>
            <Button
              variant="outlined"
              component="label"
              endIcon={<FileUploadOutlinedIcon />}
            >
              رفع ملف
              <input
                type="file"
                hidden
                accept="application/pdf"
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
            </Button>
            <Typography
              ml={1}
              fontSize={"14px"}
              variant="caption"
              color="text.secondary"
            >
              {selectedFile && selectedFile.name}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            الغاء
          </Button>
          <Button
            disabled={loadingAdd || !title.trim() || !selectedFile}
            variant="contained"
            color="success"
            onClick={isUpdatePdf ? handleUpdate : handleAdd}
          >
            {isUpdatePdf ? "تعديل" : "اضافة"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddPdf;
