import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import FolderIcon from "@mui/icons-material/Folder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Rtl from "./components/middleware/Rtl";
import { Outlet, NavLink } from "react-router-dom";
import Logo from "./components/elements/Logo";
import { Close } from "@mui/icons-material";
const drawerWidth = 240;
const navList = [
  {
    text: "جميع الاشتراكات",
    route: "/",
    icon: <InboxIcon />,
  },
  {
    text: "المواد",
    route: "/subjects",
    icon: <LibraryBooksIcon />,
  },
  {
    text: "الشهور",
    route: "/months",
    icon: <FolderIcon />,
  },
];
function App(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar sx={{ justifyContent: { md: "center", xs: "space-between" } }}>
        <Logo size="85px" />
        <IconButton
          sx={{
            display: { md: "none", sm: "block" },
          }}
          onClick={handleDrawerToggle}
        >
          <Close />
        </IconButton>
      </Toolbar>
      <Divider />
      <List className="side-nav-list">
        {navList.map((item, id) => {
          return (
            <ListItem key={id} disablePadding>
              <NavLink
                to={item.route}
                className={"side-nav-link"}
                end={item.route === "/"}
              >
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Rtl>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            ml: { md: `${drawerWidth}px` },
            backgroundColor: "#fff",
            boxShadow: "none",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Toolbar
            sx={{
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { md: "none" },
                color: "var(--blue-light)",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ fontWeight: "bold", color: "var(--blue-light)" }}
            >
              لوحة التحكم الخاصه بالمنصه
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          {/* this drawer for small devices */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          {/* this drawer for large devices */}
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            maxWidth: "100%",
            minHeight: "100vh",
            backgroundColor: "var(--background-color)",
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </Rtl>
  );
}

export default App;
