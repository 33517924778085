import React, { useState, useEffect } from "react";
import { Box, IconButton, Alert, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import swal from "sweetalert";
import apiPro from "../../api/apiPro";

const customLocalText = {
  noRowsLabel: "لا يوجد اي اشتراكات",
};

function EnrollmentTable({ setNumEnrollments, numEnrollments }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const handleDelete = (row) => {
    swal({
      title: "هل انت متأكد من حذف الاشتراك؟",
      text: "بمجرد الحذف ، سيتم حذف الاشتراك نهائيًا!",
      icon: "warning",
      buttons: ["الغاء", "حذف"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        apiPro
          .delete(`/enrollments/${row.userId}/${row.monthId}`)
          .then((response) => {
            swal("تم حذف الاشتراك بنجاح", {
              icon: "success",
            });
            setRows(
              rows.filter(
                (item) =>
                  `${item.userId}-${item.monthId}` !==
                  `${row.userId}-${row.monthId}`
              )
            );
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            swal("حصل خطأ قم بالتواصل معنا", {
              icon: "error",
            });
          });
      }
    });
  };
  const columns = [
    {
      field: "phoneNumber",
      headerName: "رقم الهاتف",
      width: 180,
    },
    {
      field: "userName",
      headerName: "اسم الطالب",
      width: 180,
    },
    {
      field: "subjectName",
      headerName: "اسم المادة",
      width: 180,
    },
    {
      field: "teacherName",
      headerName: "اسم المدرس",
      width: 180,
    },
    {
      field: "monthTitle",
      headerName: "عنوان الشهر",
      width: 180,
    },
    {
      field: "action",
      headerName: "حذف اشتراك",
      width: 130,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleDelete(params.row)}>
            <DeleteIcon color="error" />
          </IconButton>
        );
      },
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiPro.get("/enrollments");
        const data = response.data;
        setRows(data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
        console.log(error);
      }
    };
    fetchData();
  }, [numEnrollments]);
  return (
    <Box
      className="shadow"
      sx={{
        width: "100%",
        backgroundColor: "var(--white)",
        padding: "20px",
        borderRadius: "10px",
        height: "auto",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        جدول الاشتراكات
      </Typography>
      {error && (
        <Alert severity="error" sx={{ marginBottom: "10px" }}>
          حصل خطأ قم بالتواصل معنا
        </Alert>
      )}
      <Box height={400}>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooterPagination
          loading={loading}
          getRowId={(row) => `${row.userId}-${row.monthId}`}
          localeText={customLocalText}
        />
      </Box>
    </Box>
  );
}

export default EnrollmentTable;
