import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import apiPro from "../../api/apiPro";
import swal from "sweetalert";

function AddNewSubject({
  setUpdateSubjectsAfterAdd,
  isUpdate,
  setIsUpdate,
  updateSubjectData,
  setUpdateSubjectData,
}) {
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [yearOfStudy, setYearOfStudy] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [teachers, setTeachers] = useState([]);
  useEffect(() => {
    if (isUpdate) {
      setOpen(true);
      setName(updateSubjectData.name);
      setDescription(updateSubjectData.description);
      setYearOfStudy(updateSubjectData.yearOfStudy);
      setTeacherId(updateSubjectData.teacherId);
    }
  }, [isUpdate, setIsUpdate, updateSubjectData]);
  const clearFields = () => {
    setName("");
    setDescription("");
    setYearOfStudy("");
    setTeacherId("");
    setUpdateSubjectData(null);
    setIsUpdate(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpen(false);
    if (isUpdate) {
      clearFields();
    }
  };
  const handleUpdateSubject = async () => {
    try {
      setLoadingAdd(true);
      await apiPro.put(`/subjects/${updateSubjectData.id}`, {
        id: updateSubjectData.id,
        name,
        description,
        yearOfStudy,
        teacherId,
      });
      swal("تم التعديل بنجاح", {
        icon: "success",
        timer: 2000,
        buttons: false,
      });
      setOpen(false);
      setUpdateSubjectsAfterAdd((prev) => prev + 1);
      setLoadingAdd(false);
    } catch (error) {
      console.log(error);
      swal("حدث خطأ ما يرجى المحاولة مرة اخرى", {
        icon: "error",
      });
      setLoadingAdd(false);
    }
    clearFields();
  };

  const handleAddSubject = async () => {
    try {
      setLoadingAdd(true);
      await apiPro.post("/subjects", {
        name,
        description,
        yearOfStudy,
        teacherId,
      });
      swal("تمت الاضافة بنجاح", {
        icon: "success",
        timer: 2000,
        buttons: false,
      });
      setOpen(false);
      setUpdateSubjectsAfterAdd((prev) => prev + 1);
      setLoadingAdd(false);
      clearFields();
    } catch (error) {
      console.log(error);
      swal("حدث خطأ ما يرجى المحاولة مرة اخرى", {
        icon: "error",
      });
      setLoadingAdd(false);
    }
  };

  useEffect(() => {
    const getAllTeachers = async () => {
      try {
        const { data } = await apiPro.get("/subjects/options");
        setTeachers(data);
      } catch (error) {
        console.log(error);
      }
    };
    getAllTeachers();
  }, []);
  return (
    <Box mb={2}>
      <Button
        variant="contained"
        color="primary"
        endIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        اضافة ماده جديده
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle sx={{ color: "var(--blue-light)" }}>
          {isUpdate ? "تعديل ماده" : "اضافة ماده جديده"}
        </DialogTitle>
        {loadingAdd && <LinearProgress />}
        <DialogContent dividers>
          <TextField
            margin="normal"
            label="اسم الماده"
            fullWidth
            name="subjectName"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="normal"
            label="وصف الماده"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
            placeholder="اكتب وصف الماده هنا ..."
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="selectedYear">اختر السنة</InputLabel>
            <Select
              value={yearOfStudy}
              onChange={(e) => setYearOfStudy(e.target.value)}
              label="اختر السنة"
              id="selectedYear"
            >
              <MenuItem value={1}>الصف الأول الثانوي</MenuItem>
              <MenuItem value={2}>الصف الثاني الثانوي</MenuItem>
              <MenuItem value={3}>الصف الثالث الثانوي</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="selectedTeacher">اختر المدرس</InputLabel>
            <Select
              value={teacherId}
              onChange={(e) => setTeacherId(e.target.value)}
              label="اختر المدرس"
              id="selectedTeacher"
            >
              {teachers.map((teacher) => (
                <MenuItem key={teacher.id} value={teacher.id}>
                  {teacher.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            الغاء
          </Button>

          <Button
            disabled={
              !name || !description || !yearOfStudy || !teacherId || loadingAdd
            }
            variant="contained"
            color="primary"
            onClick={isUpdate ? handleUpdateSubject : handleAddSubject}
          >
            {isUpdate ? "تعديل" : "اضافة"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddNewSubject;
