import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Alert,
  Typography,
  Avatar,
  Chip,
  Badge,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ImgUpdateForm from "../elements/ImgUpdateForm";
import { DataGrid } from "@mui/x-data-grid";
import swal from "sweetalert";
import apiPro from "../../api/apiPro";
import { useNavigate } from "react-router-dom";
const customLocalText = {
  noRowsLabel: "لم يتم اضافة اي شهور حتي الان",
};
function MonthsTable({
  setIsUpdate,
  setUpdateMonthData,
  updateMonthsAfterAdd,
}) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [updateAfterImg, setUpdateAfterImg] = useState(0);
  const columns = [
    {
      field: "title",
      headerName: "اسم الشهر",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "subjectName",
      headerName: "اسم الماده",
      width: 100,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "teacherName",
      headerName: "اسم المدرس",
      width: 140,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "yearOfStudy",
      headerName: "السنه الدراسيه",
      width: 120,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        if (params.row.yearOfStudy === 1) return "الاول الثانوي";
        else if (params.row.yearOfStudy === 2) return "الثاني الثانوي";

        return "الثالث الثانوي";
      },
    },
    {
      field: "description",
      headerName: "وصف الشهر",
      width: 200,
    },
    {
      field: "monthNumber",
      headerName: "رقم الشهر",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "image",
      headerName: "صورة الشهر",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        return (
          <Avatar
            alt={row.title}
            src={row.image}
            sx={{ width: 70, height: 70, borderRadius: "0px" }}
            loading="lazy"
          />
        );
      },
    },
    {
      field: "price",
      headerName: "سعر الشهر",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "حذف/تعديل",
      width: 120,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton title="حذف" onClick={() => handleDelete(row)}>
              <DeleteIcon color="error" />
            </IconButton>
            <IconButton onClick={() => handleUpdate(row)}>
              <EditIcon color="primary" />
            </IconButton>
            <ImgUpdateForm
              id={row.id}
              route={"months"}
              update={setUpdateAfterImg}
            />
          </>
        );
      },
    },
    {
      field: "lessons",
      headerName: "الدروس",
      width: 120,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <Badge badgeContent={row.numberOfLessons} color="info">
            <Chip
              label="عرض الدروس"
              onClick={() => navigate(`/months/${row.id}/lessons`)}
              color="info"
              variant="outlined"
            />
          </Badge>
        );
      },
    },
  ];
  // update
  const handleUpdate = (row) => {
    setIsUpdate(true);
    setUpdateMonthData(row);
  };
  // delete
  const handleDelete = (row) => {
    swal({
      title: "هل انت متأكد من حذف الشهر؟",
      text: "بمجرد الحذف سيتم حذف جميع محتويات الشهر من دروس وملفات واختبارات وايضا اشتراك الطلاب في الشهر",
      icon: "warning",
      buttons: ["الغاء", "حذف"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        apiPro
          .delete(`/months/${row.id}`)
          .then((response) => {
            swal("تم حذف الماده بنجاح", {
              icon: "success",
            });
            setRows(rows.filter((item) => item.id !== row.id));
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            swal("حصل خطأ قم بالتواصل معنا", {
              icon: "error",
            });
          });
      }
    });
  };
  // get
  useEffect(() => {
    const getAllMonths = async () => {
      try {
        const { data } = await apiPro.get("/months");
        setRows(data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };
    getAllMonths();
  }, [updateAfterImg, updateMonthsAfterAdd]);

  return (
    <Box
      className="shadow"
      sx={{
        width: "100%",
        backgroundColor: "var(--white)",
        padding: "20px",
        borderRadius: "10px",
        height: "auto",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        جميع الشهور
      </Typography>
      {error && (
        <Alert severity="error" sx={{ marginBottom: "10px" }}>
          حصل خطأ قم بالتواصل معنا
        </Alert>
      )}
      <Box height={450}>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooterPagination
          loading={loading}
          getRowId={(row) => row.id}
          localeText={customLocalText}
          rowHeight={80}
        />
      </Box>
    </Box>
  );
}

export default MonthsTable;
