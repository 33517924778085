import React from "react";

function Logo({ size = "100px" }) {
  return (
    <img
      src="/logo.png"
      alt="logo"
      style={{
        width: size,
        aspectRatio: "auto",
      }}
      
    />
  );
}

export default Logo;
