import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import apiPro from "../../api/apiPro";
import swal from "sweetalert";

function AddNewMonth({
  setUpdateMonthsAfterAdd,
  updateMonthData,
  setUpdateMonthData,
  isUpdate,
  setIsUpdate,
}) {
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [yearOfStudy, setYearOfStudy] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [price, setPrice] = useState(0);
  const [monthNumber, setMonthNumber] = useState(1);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpen(false);
    if (isUpdate) {
      clearFields();
    }
  };
  const clearFields = () => {
    setTitle("");
    setDescription("");
    setSubjectId("");
    setTeacherId("");
    setYearOfStudy("");
    setUpdateMonthData(null);
    setIsUpdate(false);
  };

  useEffect(() => {
    if (isUpdate) {
      setOpen(true);
      setTitle(updateMonthData.title);
      setDescription(updateMonthData.description);
      setSubjectId(updateMonthData.subjectId);
      setTeacherId(updateMonthData.teacherId);
      setYearOfStudy(updateMonthData.yearOfStudy);
      setPrice(updateMonthData.price);
      setMonthNumber(updateMonthData.monthNumber);
    }
  }, [isUpdate, setIsUpdate, updateMonthData]);
  useEffect(() => {
    const getOptions = async () => {
      try {
        const { data } = await apiPro.get("/months/options");
        setSubjects(data.subjects);
        setTeachers(data.teachers);
      } catch (error) {
        console.log(error);
      }
    };
    getOptions();
  }, []);
  const handleUpdateMonth = async () => {
    setLoadingAdd(true);
    try {
      await apiPro.put(`/months/${updateMonthData.id}`, {
        id: updateMonthData.id,
        title,
        description,
        subjectId,
        teacherId,
        monthNumber,
        price,
      });
      setUpdateMonthsAfterAdd((prev) => prev + 1);
      swal("تم التعديل بنجاح", "", "success");
    } catch (error) {
      if (error.response.status === 500) {
        swal("حصل خطأ قم بالتواصل معنا", {
          icon: "error",
        });
      } else {
        swal("فشل التعديل", "خطأ في البيانات", "error");
      }
      console.log(error);
    }
    setOpen(false);
    setLoadingAdd(false);
    clearFields();
  };
  const handleAddMonth = async () => {
    setLoadingAdd(true);
    try {
      await apiPro.post("/months", {
        title,
        description,
        subjectId,
        teacherId,
        yearOfStudy,
        monthNumber,
        price,
      });
      setUpdateMonthsAfterAdd((prev) => prev + 1);
      swal("تم الاضافة بنجاح", "", "success");
      clearFields();
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
    setLoadingAdd(false);
  };
  useEffect(() => {
    setSubjectId("");
  }, [teacherId, yearOfStudy]);
  return (
    <Box mb={2}>
      <Button
        variant="contained"
        endIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        اضافة شهر جديد
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle sx={{ color: "var(--blue-light)" }}>
          {isUpdate ? "تعديل شهر" : "اضافة شهر جديد"}
        </DialogTitle>
        {loadingAdd && <LinearProgress />}
        <DialogContent dividers>
          <TextField
            margin="normal"
            label="عنوان الشهر"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
          />
          <TextField
            margin="normal"
            label="وصف الشهر"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            placeholder="اكتب وصف الشهر هنا ..."
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="selectedYear">اختر السنة</InputLabel>
            <Select
              value={yearOfStudy}
              onChange={(e) => setYearOfStudy(e.target.value)}
              label="اختر السنة"
              id="selectedYear"
            >
              <MenuItem value={1}>الصف الأول الثانوي</MenuItem>
              <MenuItem value={2}>الصف الثاني الثانوي</MenuItem>
              <MenuItem value={3}>الصف الثالث الثانوي</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="selectedTeacher">اختر المدرس</InputLabel>
            <Select
              value={teacherId}
              onChange={(e) => setTeacherId(e.target.value)}
              label="اختر المدرس"
              id="selectedTeacher"
            >
              {teachers.map((teacher) => (
                <MenuItem key={teacher.id} value={teacher.id}>
                  {teacher.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type="number"
            margin="normal"
            label="رقم الشهر"
            value={monthNumber}
            onChange={(e) => setMonthNumber(e.target.value)}
            fullWidth
            inputProps={{ min: 1, max: 12 }}
            helperText="من 1 الى 12"
          />
          {teacherId !== "" && yearOfStudy !== "" && (
            <FormControl fullWidth margin="normal">
              {/* need to filter subjects that equal yearOfStudy and teacherId */}
              <InputLabel id="selectedSubject">اختر الماده</InputLabel>
              <Select
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
                label="اختر الماده"
                id="selectedSubject"
              >
                {subjects
                  .filter((item) => {
                    return (
                      item.studyYear === yearOfStudy &&
                      item.teacherId === teacherId
                    );
                  })
                  .map((subject) => (
                    <MenuItem key={subject.id} value={subject.id}>
                      {subject.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          <TextField
            type="number"
            margin="normal"
            label="سعر الشهر"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            fullWidth
            inputProps={{ min: 0 }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            الغاء
          </Button>

          <Button
            disabled={
              !title ||
              !description ||
              !yearOfStudy ||
              !teacherId ||
              loadingAdd ||
              !price ||
              !monthNumber ||
              subjectId === ""
            }
            variant="contained"
            color="primary"
            onClick={isUpdate ? handleUpdateMonth : handleAddMonth}
          >
            {isUpdate ? "تعديل" : "اضافة"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddNewMonth;
