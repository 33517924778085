const getYearName = (year) => {
  if (year === 1) {
    return "الصف الأول الثانوي";
  } else if (year === 2) {
    return "الصف الثاني الثانوي";
  }
  return "الصف الثالث الثانوي";
};

export { getYearName };
