import axios from "axios";
import store from "../store/main";
import swal from "sweetalert";
const { dispatch } = store;

let baseURL = "https://localhost:7039/api/admin";
if (process.env.NODE_ENV === "production") {
  baseURL = "https://dras.runasp.net/api/admin";
}

const apiPro = axios.create({ baseURL });

apiPro.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiPro.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      swal(
        "انتهت فترة تسجيل الدخول",
        "الرجاء تسجيل الدخول مرة اخرى",
        "warning"
      );
      dispatch({ type: "auth/logout" });
    }

    return Promise.reject(error);
  }
);

export default apiPro;
