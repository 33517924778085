import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import AddIcon from "@mui/icons-material/Add";
import apiPro from "../../api/apiPro";
import swal from "sweetalert";

function AddNewEnrollment({ setNumEnrollments }) {
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [yearOfStudy, setYearOfStudy] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [errorPhoneMessage, setErrorPhoneMessage] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [months, setMonths] = useState([]);
  const [subjectId, setSubjectId] = useState("");
  const [teacherId, setTeacherId] = useState("");
  const [monthId, setMonthId] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpen(false);
  };

  const addNewEnrollment = async () => {
    setLoadingAdd(true);
    try {
      await apiPro.post("/enrollments", {
        userPhone: phoneNumber,
        monthId,
      });
      setNumEnrollments((prev) => prev + 1);
      swal("تم اضافة الاشتراك بنجاح", {
        icon: "success",
        timer: 2000,
        buttons: false,
      });
      setLoadingAdd(false);
      setOpen(false);
    } catch (error) {
      setLoadingAdd(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const checkPhone = async () => {
      try {
        const response = await apiPro.get(
          `/students/check-phone-number/${phoneNumber}`
        );
        setValidPhone(response.data);
        if (response.data) setErrorPhoneMessage("تمام ..الرقم موجود");
        else setErrorPhoneMessage("الرقم غير موجود...  لازم يعمل حساب اولا");
      } catch (error) {
        console.log(error);
      }
    };
    if (phoneNumber.length === 11) checkPhone();
    else {
      setValidPhone(false);
      setErrorPhoneMessage("");
    }
  }, [phoneNumber]);

  useEffect(() => {
    const getOptions = async () => {
      try {
        const { data } = await apiPro.get("/enrollments/options");
        setSubjects(data.subjects);
        setTeachers(data.teachers);
        setMonths(data.months);
      } catch (error) {
        console.log(error);
      }
    };
    getOptions();
  }, []);
  useEffect(() => {
    setSubjectId("");
    setMonthId("");
  }, [teacherId, yearOfStudy]);

  return (
    <Box mb={2}>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="primary"
        endIcon={<AddIcon />}
      >
        اضافة اشتراك جديد
      </Button>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>اضافة اشتراك جديد</DialogTitle>
        {loadingAdd && <LinearProgress />}
        <DialogContent dividers>
          <TextField
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
            label="رقم هاتف الطالب"
            margin="normal"
            focused
            color={`${validPhone ? "success" : "error"}`}
            helperText={`${phoneNumber.length !== 0 ? errorPhoneMessage : ""}`}
            FormHelperTextProps={{
              style: {
                color: `${validPhone ? "green" : "red"}`,
              },
            }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="selectedYear">اختر السنة</InputLabel>
            <Select
              value={yearOfStudy}
              onChange={(e) => setYearOfStudy(e.target.value)}
              label="اختر السنة"
              id="selectedYear"
            >
              <MenuItem value="1">الصف الأول الثانوي</MenuItem>
              <MenuItem value="2">الصف الثاني الثانوي</MenuItem>
              <MenuItem value="3">الصف الثالث الثانوي</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="selectedTeacher">اختر المدرس</InputLabel>
            <Select
              value={teacherId}
              onChange={(e) => setTeacherId(e.target.value)}
              label="اختر المدرس"
              id="selectedTeacher"
            >
              {teachers.map((teacher) => (
                <MenuItem key={teacher.id} value={teacher.id}>
                  {teacher.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {teacherId.length !== 0 && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="selectedSubject">اختر المادة</InputLabel>
              <Select
                value={subjectId}
                onChange={(e) => setSubjectId(e.target.value)}
                label="اختر المادة"
                id="selectedSubject"
              >
                {subjects
                  .filter(
                    (subject) =>
                      subject.teacherId === teacherId &&
                      subject.studyYear === +yearOfStudy
                  )
                  .map((subject) => (
                    <MenuItem key={subject.id} value={subject.id}>
                      {subject.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {subjectId.length !== 0 && teacherId.length !== 0 && (
            <FormControl fullWidth margin="normal">
              <InputLabel id="selectedMonth">اختر الشهر</InputLabel>
              <Select
                value={monthId}
                onChange={(e) => setMonthId(e.target.value)}
                label="اختر الشهر"
                id="selectedMonth"
              >
                {months
                  .filter((month) => month.subjectId === subjectId)
                  .map((month) => (
                    <MenuItem key={month.id} value={month.id}>
                      {month.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            الغاء
          </Button>
          <Button
            disabled={
              phoneNumber.length !== 11 ||
              teacherId.length === 0 ||
              subjectId.length === 0 ||
              monthId.length === 0 ||
              !validPhone ||
              yearOfStudy.length === 0 ||
              loadingAdd
            }
            onClick={addNewEnrollment}
            color="primary"
            variant="contained"
          >
            اضافة
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddNewEnrollment;
