import React, { useState, useEffect } from "react";
import {
  Box,
  Alert,
  Typography,
  Chip,
  Stack,
  Divider,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import apiPro from "../../api/apiPro";
import { useParams } from "react-router-dom";
import { getYearName } from "../../utils/helpers";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import swal from "sweetalert";
const customLocalText = {
  noRowsLabel: "لم يتم اضافه اي دروس حتي الان",
};

function LessonsTable({
  updateLessonsAfterAdd,
  setUpdateLessonData,
  setIsUpdatePdf,
  setIsUpdateVideo,
  setIsUpdateQuiz,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [info, setInfo] = useState({});
  const { id } = useParams();
  const columns = [
    {
      field: "title",
      headerName: "اسم الدرس",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "النوع",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let title = "";
        let color = "";
        if (params.value === "video") {
          title = "فيديو";
          color = "primary";
        } else if (params.value === "pdf") {
          title = "ملف";
          color = "success";
        } else {
          title = "اختبار";
          color = "secondary";
        }
        return <Chip label={title} color={color} variant="contained" />;
      },
    },
    {
      field: "action",
      headerName: "حذف/تعديل",
      width: 120,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton title="حذف" onClick={() => handleDelete(row)}>
              <DeleteIcon color="error" />
            </IconButton>
            <IconButton onClick={() => handleUpdate(row)}>
              <EditIcon color="primary" />
            </IconButton>
          </>
        );
      },
    },
  ];
  const handleDelete = (row) => {
    swal({
      title: "هل انت متاكد من الحذف؟",
      text: "سيتم حذف الدرس نهائيا من الشهر..ولا يمكن مشاهدته بواسطة الطلاب",
      icon: "warning",
      buttons: ["الغاء", "حذف"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        setLoading(true);
        try {
          await apiPro.delete(`/months/${id}/lessons/${row.type}/${row.id}`);
          swal("تم الحذف بنجاح", {
            icon: "success",
          });
          setRows((prev) => prev.filter((r) => r.id !== row.id));
        } catch (error) {
          console.log(error);
          swal("حصل خطأ اثناء الحذف", {
            icon: "error",
          });
        }
        setLoading(false);
      }
    });
  };
  const handleUpdate = (row) => {
    setUpdateLessonData(row);
    if (row.type === "pdf") {
      setIsUpdatePdf(true);
    } else if (row.type === "video") {
      setIsUpdateVideo(true);
    } else {
      setIsUpdateQuiz(true);
    }
  };

  useEffect(() => {
    const getLessons = async () => {
      try {
        const { data } = await apiPro.get(`/months/${id}/lessons`);
        setRows(data.lessons);
        setInfo(data.info);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(true);
        setLoading(false);
      }
    };
    getLessons();
  }, [updateLessonsAfterAdd, id]);
  return (
    <Box
      className="shadow"
      sx={{
        width: "100%",
        backgroundColor: "var(--white)",
        padding: "20px",
        borderRadius: "10px",
        height: "auto",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: "10px" }}>
        جدول الدروس
      </Typography>
      {info?.monthName && (
        <Stack
          spacing={1}
          direction={{ xs: "column", sm: "row" }}
          mb={2}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Chip variant="contained" label={info.monthName} />
          <Chip variant="contained" label={info.subjectName} />
          <Chip variant="contained" label={info.teacherName} />
          <Chip variant="contained" label={getYearName(info.yearOfStudy)} />
        </Stack>
      )}
      {error && (
        <Alert severity="error" sx={{ marginBottom: "10px" }}>
          حصل خطأ قم بالتواصل معنا
        </Alert>
      )}
      <Box height={420}>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooterPagination
          getRowId={(row) => row.id}
          localeText={customLocalText}
          rowHeight={55}
          loading={loading}
          
        />
      </Box>
    </Box>
  );
}

export default LessonsTable;
