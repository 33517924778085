import React, { useState } from "react";
import { Box } from "@mui/material";
import EnrollmentTable from "./EnrollmentTable";
import AddNewEnrollment from "./AddNewEnrollment";
function AllEnrollments() {
  // const [updateEnrollments, setUpdateEnrollments] = useState(false);
  const [numEnrollments, setNumEnrollments] = useState(0);
  return (
    <Box>
      <AddNewEnrollment setNumEnrollments={setNumEnrollments} />
      <EnrollmentTable
        numEnrollments={numEnrollments}
        setNumEnrollments={setNumEnrollments}
      />
    </Box>
  );
}

export default AllEnrollments;
