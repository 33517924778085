import React, { useState, useEffect } from "react";
import { Box, IconButton, Alert, Typography, Avatar } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ImgUpdateForm from "../elements/ImgUpdateForm";
import { DataGrid } from "@mui/x-data-grid";
import swal from "sweetalert";
import apiPro from "../../api/apiPro";

const customLocalText = {
  noRowsLabel: "لا يوجد اي مواد",
};

function SubjectTable({
  updateSubjectsAfterAdd,
  setIsUpdate,
  setUpdateSubjectData,
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [updateAfterImg, setUpdateAfterImg] = useState(0);
  const columns = [
    {
      field: "name",
      headerName: "اسم الماده",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "yearOfStudy",
      headerName: "السنه الدراسيه",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "وصف الماده",
      width: 280,
    },
    {
      field: "teacherName",
      headerName: "اسم المدرس",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "duration",
      headerName: "عدد الشهور",
      width: 110,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "image",
      headerName: "صورة الماده",
      width: 120,
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        return (
          <Avatar
            alt={row.name}
            src={row.image}
            sx={{ width: 70, height: 70, borderRadius: "0px" }}
            loading="lazy"
          />
        );
      },
    },
    {
      field: "action",
      headerName: "حذف/تعديل",
      width: 130,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton title="حذف" onClick={() => handleDelete(row)}>
              <DeleteIcon color="error" />
            </IconButton>
            <IconButton onClick={() => handleUpdate(row)}>
              <EditIcon color="primary" />
            </IconButton>
            <ImgUpdateForm
              id={row.id}
              route={"subjects"}
              update={setUpdateAfterImg}
            />
          </>
        );
      },
    },
  ];
  const handleUpdate = (row) => {
    setIsUpdate(true);
    setUpdateSubjectData(row);
  };
  const handleDelete = (row) => {
    swal({
      title: "هل انت متأكد من حذف الماده؟",
      text: "بمجرد الحذف ، سيتم حذف الماده نهائيًا!",
      icon: "warning",
      buttons: ["الغاء", "حذف"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        apiPro
          .delete(`/subjects/${row.id}`)
          .then((response) => {
            swal("تم حذف الماده بنجاح", {
              icon: "success",
            });
            setRows(rows.filter((item) => item.id !== row.id));
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            swal("حصل خطأ قم بالتواصل معنا", {
              icon: "error",
            });
          });
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    const getSubjects = async () => {
      try {
        const { data } = await apiPro.get("/subjects");
        setRows(data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };
    getSubjects();
  }, [updateSubjectsAfterAdd, updateAfterImg]);
  return (
    <Box
      className="shadow"
      sx={{
        width: "100%",
        backgroundColor: "var(--white)",
        padding: "20px",
        borderRadius: "10px",
        height: "auto",
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: "20px" }}>
        جميع المواد
      </Typography>
      {error && (
        <Alert severity="error" sx={{ marginBottom: "10px" }}>
          حصل خطأ قم بالتواصل معنا
        </Alert>
      )}
      <Box height={450}>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooterPagination
          loading={loading}
          getRowId={(row) => row.id}
          localeText={customLocalText}
          rowHeight={80}
        />
      </Box>
    </Box>
  );
}

export default SubjectTable;
