import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/main";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AllEnrollments from "./components/enrollments/AllEnrollments";
import Login from "./components/Login";
import Protected from "./utils/Protected";
import ProtectedAuth from "./utils/ProtectedAuth";
import Subjects from "./components/subjects/Subjects";
import Months from "./components/months/Months";
import Lesson from "./components/lessons/Lesson";
import Error404 from "./components/elements/Error404";
const ROUTES = createBrowserRouter([
  {
    path: "/",
    errorElement: <Error404 />,
    element: (
      <Protected>
        <App />
      </Protected>
    ),
    children: [
      {
        index: true,
        element: <AllEnrollments />,
      },
      {
        path: "/subjects",
        element: <Subjects />,
      },
      {
        path: "/months",
        element: <Months />,
      },
      {
        path: "months/:id/lessons",
        element: <Lesson />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <ProtectedAuth>
        <Login />
      </ProtectedAuth>
    ),
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={ROUTES} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
