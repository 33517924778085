import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Rtl from "./middleware/Rtl";
import swal from "sweetalert";
import { login } from "../store/authSlice";
import Logo from "./elements/Logo";
function Login() {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const { authLoading } = useSelector((state) => state.auth);

  const handleLogin = () => {
    dispatch(login({ phoneNumber, password }))
      .unwrap()
      .then((res) => {
        swal("تم تسجيل الدخول بنجاح", {
          icon: "success",
          buttons: false,
          timer: 2000,
        });
      })
      .catch((err) => {
        swal("خطأ", err, "error");
      });
  };

  return (
    <Rtl>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "var(--background-color)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "var(--white)",
            borderRadius: "10px",
            padding: "20px",
            width: { xs: "90%", sm: "50%", md: "30%" },
          }}
          className="shadow"
        >
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Logo size="140px"/>
          </Box>
          <Typography
            sx={{
              color: "var(--blue-dark)",
              fontSize: "2rem",
              fontWeight: "bold",
              mb: 1,
            }}
          >
            تسجيل الدخول
          </Typography>
          <Typography
            sx={{
              color: "var(--blue-dark)",
              mb: 4,
            }}
          >
            تسجيل دخول الاداره
          </Typography>
          <TextField
            sx={{
              width: { xs: "100%", md: "80%" },
              marginBottom: "20px",
            }}
            id="phoneNumber"
            label="رقم الهاتف"
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            sx={{
              width: { xs: "100%", md: "80%" },
              marginBottom: "20px",
            }}
            id="password"
            label="كلمة المرور"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            sx={{
              width: { xs: "100%", md: "50%" },
              marginBottom: "20px",
            }}
            disabled={authLoading}
            variant="contained"
            onClick={handleLogin}
            endIcon={
              authLoading && <CircularProgress size={20} color="inherit" />
            }
          >
            تسجيل الدخول
          </Button>
        </Box>
      </Box>
    </Rtl>
  );
}

export default Login;
