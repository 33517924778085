import React, { useState } from "react";
import SubjectTable from "./SubjectTable";
import AddNewSubject from "./AddNewSubject";
import { Box } from "@mui/material";

function Subjects() {
  const [updateSubjectsAfterAdd, setUpdateSubjectsAfterAdd] = useState(0);
  const [updateSubjectData, setUpdateSubjectData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  
  return (
    <Box>
      <AddNewSubject
        setUpdateSubjectsAfterAdd={setUpdateSubjectsAfterAdd}
        updateSubjectData={updateSubjectData}
        setUpdateSubjectData={setUpdateSubjectData}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
      />
      <SubjectTable
        updateSubjectsAfterAdd={updateSubjectsAfterAdd}
        setIsUpdate={setIsUpdate}
        setUpdateSubjectData={setUpdateSubjectData}
      />
    </Box>
  );
}

export default Subjects;
