import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LinearProgress from "@mui/material/LinearProgress";
import swal from "sweetalert";
import apiPro from "../../api/apiPro";
import { useParams } from "react-router-dom";
function AddVideo({
  setUpdateLessonsAfterAdd,
  updateLessonData,
  setUpdateLessonData,
  isUpdateVideo,
  setIsUpdateVideo,
}) {
  console.log("updateLessonData", updateLessonData);
  const { id } = useParams();
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");

  const clearFields = () => {
    setTitle("");
    setVideoUrl("");
    setIsUpdateVideo(false);
    setUpdateLessonData(null);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpen(false);
    if (isUpdateVideo) {
      clearFields();
    }
  };
  const handleAdd = async () => {
    setLoadingAdd(true);
    try {
      await apiPro.post(`/months/${id}/lessons/video`, {
        title,
        videoUrl,
      });
      swal("تمت الاضافة بنجاح", {
        icon: "success",
      });
      setUpdateLessonsAfterAdd((prev) => prev + 1);
    } catch (error) {
      console.log(error);
      swal("حدث خطأ ما يرجى المحاولة مرة اخرى", {
        icon: "error",
      });
    }
    setLoadingAdd(false);
    setOpen(false);
    setTitle("");
    setVideoUrl("");
  };
  useEffect(() => {
    if (isUpdateVideo) {
      setOpen(true);
      setTitle(updateLessonData.title);
      setVideoUrl("updateLessonData.videoUrl");
    }
  }, [isUpdateVideo, updateLessonData]);
  const handleUpdate = async () => {};
  return (
    <Box>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        اضافة فيديو
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          {isUpdateVideo ? "تعديل الفيديو" : "اضافة فيديو"}
        </DialogTitle>
        {loadingAdd && <LinearProgress />}
        <DialogContent dividers>
          <TextField
            margin="normal"
            label="عنوان الفيديو"
            fullWidth
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            margin="normal"
            label="رابط الفيديو"
            fullWidth
            variant="outlined"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            الغاء
          </Button>
          <Button
            disabled={loadingAdd || title.length === 0 || videoUrl.length === 0}
            onClick={isUpdateVideo ? handleUpdate : handleAdd}
            color="primary"
            variant="contained"
          >
            {isUpdateVideo ? "تعديل" : "اضافة"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddVideo;
