import React, { useState } from "react";
import AddNewMonth from "./AddNewMonth";
import MonthsTable from "./MonthsTable";
import { Box } from "@mui/material";
function Months() {
  const [updateMonthsAfterAdd, setUpdateMonthsAfterAdd] = useState(0);
  const [updateMonthData, setUpdateMonthData] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  return (
    <Box>
      <AddNewMonth
        setUpdateMonthsAfterAdd={setUpdateMonthsAfterAdd}
        updateMonthData={updateMonthData}
        setUpdateMonthData={setUpdateMonthData}
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
      />
      <MonthsTable
        updateMonthsAfterAdd={updateMonthsAfterAdd}
        setIsUpdate={setIsUpdate}
        setUpdateMonthData={setUpdateMonthData}
      />
    </Box>
  );
}

export default Months;
