import axios from "axios";

let baseURL = "https://localhost:7039/api/admin";
if (process.env.NODE_ENV === "production") {
  baseURL = "https://dras.runasp.net/api/admin";
}

const api = axios.create({ baseURL });

export default api;
