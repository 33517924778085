import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import LessonsTable from "./LessonsTable";
import AddVideo from "./AddVideo";
import AddQuiz from "./quiz/AddQuiz";
import AddPdf from "./AddPdf";
function Lesson() {
  const [updateLessonsAfterAdd, setUpdateLessonsAfterAdd] = useState(0);
  const [updateLessonData, setUpdateLessonData] = useState({});
  const [isUpdatePdf, setIsUpdatePdf] = useState(false);
  const [isUpdateVideo, setIsUpdateVideo] = useState(false);
  const [isUpdateQuiz, setIsUpdateQuiz] = useState(false);

  return (
    <Box>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          width: "100%",
          backgroundColor: "var(--white)",
          padding: "20px",
          borderRadius: "10px",
        }}
        spacing={2}
        mb={2}
      >
        <AddVideo
          setUpdateLessonsAfterAdd={setUpdateLessonsAfterAdd}
          updateLessonData={updateLessonData}
          isUpdateVideo={isUpdateVideo}
          setIsUpdateVideo={setIsUpdateVideo}
          setUpdateLessonData={setUpdateLessonData}
        />
        <AddPdf
          setUpdateLessonsAfterAdd={setUpdateLessonsAfterAdd}
          updateLessonData={updateLessonData}
          isUpdatePdf={isUpdatePdf}
          setIsUpdatePdf={setIsUpdatePdf}
          setUpdateLessonData={setUpdateLessonData}
        />
        <AddQuiz
          setUpdateLessonsAfterAdd={setUpdateLessonsAfterAdd}
          updateLessonData={updateLessonData}
          isUpdateQuiz={isUpdateQuiz}
          setIsUpdateQuiz={setIsUpdateQuiz}
          setUpdateLessonData={setUpdateLessonData}
        />
      </Stack>

      <LessonsTable
        updateLessonsAfterAdd={updateLessonsAfterAdd}
        setUpdateLessonData={setUpdateLessonData}
        setIsUpdatePdf={setIsUpdatePdf}
        setIsUpdateVideo={setIsUpdateVideo}
        setIsUpdateQuiz={setIsUpdateQuiz}
      />
    </Box>
  );
}

export default Lesson;
