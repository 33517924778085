import React from "react";
import {
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

function QuestionCard({ index, setQuestions, questions, question }) {
  const handleQuestionChange = (e) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      newQuestions[index].question = e.target.value;
      return newQuestions;
    });
  };
  const handleOptionChange = (e) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      newQuestions[index].options[e.target.name] = e.target.value;
      return newQuestions;
    });
  };
  const handleCorrectAnswerChange = (e) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      newQuestions[index].correctAnswer = e.target.value;
      return newQuestions;
    });
  };
  const handleDeleteQuestion = () => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      newQuestions.splice(index, 1);
      return newQuestions;
    });
  };
  const handleDeleteOption = (optionIndex) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      newQuestions[index].options.splice(optionIndex, 1);
      newQuestions[index].correctAnswer = 0;
      return newQuestions;
    });
  };
  return (
    <Box
      className="shadow"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: "var(--background-color-dark)",
        padding: "20px",
        borderRadius: "15px",
        margin: "20px 0",
        border: "1px solid var(--blue-dark)",
      }}
    >
      <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
        <IconButton
          title="حذف السؤال"
          color="error"
          onClick={handleDeleteQuestion}
        >
          <DeleteIcon />
        </IconButton>
        <Typography variant="h6" sx={{ color: "var(--blue-dark)" }}>
          السؤال {index + 1}
        </Typography>
      </Stack>
      <TextField
        label={`السؤال ${index + 1}`}
        variant="outlined"
        fullWidth
        margin="normal"
        value={question.question}
        onChange={handleQuestionChange}
      />
      {question.options.map((option, optionIndex) => (
        <Box
          key={optionIndex}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            margin: "10px 0",
          }}
        >
          <IconButton
            title="حذف الاجابة"
            color="error"
            onClick={() => handleDeleteOption(optionIndex)}
          >
            <DeleteIcon />
          </IconButton>

          <FormControl component="fieldset" sx={{ width: "100%" }}>
            <RadioGroup
              row
              aria-label="quiz"
              name="quiz"
              value={question.correctAnswer}
              onChange={handleCorrectAnswerChange}
            >
              <FormControlLabel
                value={optionIndex}
                control={<Radio />}
                label={`الاجابة ${optionIndex + 1}`}
              />
            </RadioGroup>
          </FormControl>
          <TextField
            label={`الاجابة ${optionIndex + 1}`}
            variant="outlined"
            fullWidth
            margin="normal"
            value={option}
            name={optionIndex}
            onChange={handleOptionChange}
          />
        </Box>
      ))}
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setQuestions((prev) => {
            const newQuestions = [...prev];
            newQuestions[index].options.push("");
            return newQuestions;
          });
        }}
      >
        اضافة اجابه
      </Button>
    </Box>
  );
}

export default QuestionCard;
