import React, { useState } from "react";
import FilterIcon from "@mui/icons-material/Filter";
import {
  Box,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
} from "@mui/material";
import apiPro from "../../api/apiPro";
import swal from "sweetalert";
function ImgUpdateForm({ id, route, update }) {
  const [open, setOpen] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpen(false);
  };
  const handleUpdate = async () => {
    try {
      setLoadingUpdate(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      await apiPro.put(`/${route}/${id}/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      swal("تم تعديل الصوره بنجاح", {
        icon: "success",
        timer: 2000,
        buttons: false,
      });
      update((prev) => prev + 1);
      setOpen(false);
      setLoadingUpdate(false);
    } catch (error) {
      console.log(error);
      swal("حدث خطأ ما يرجى المحاولة مرة اخرى", {
        icon: "error",
      });
      setLoadingUpdate(false);
    }
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <FilterIcon color="secondary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>تعديل الصوره</DialogTitle>
        <DialogContent dividers>
          <Box>
            {loadingUpdate ? (
              <h5>جاري تعديل الصوره...</h5>
            ) : (
              <TextField
                type="file"
                fullWidth
                onChange={(e) => setSelectedFile(e.target.files[0])}
                inputProps={{
                  accept: "image/*",
                }}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box>
            <Button onClick={handleClose} color="error">
              الغاء
            </Button>
            <Button onClick={handleUpdate}>تعديل</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ImgUpdateForm;
