import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/axios";

const login = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      const request = await api.post("/login", data);
      const token = request.data.token;
      const user = request.data.user;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      return { token, user };
    } catch (error) {
      if (!error.response) {
        return rejectWithValue("حصل خطأ ما..قم بالتواصل مع الدعم");
      }
      return rejectWithValue("الفون أو كلمة السر غير صحيحة 😥");
    }
  }
);

const validToken = () => {
  const token = localStorage.getItem("token") || null;
  if (!token || token === "undefined" || token.length < 10) {
    return null;
  }
  return token;
};
const validUser = () => {
  const user = JSON.parse(localStorage.getItem("user")) || null;
  let isValid = true;
  const validUser = [
    "firstName",
    "lastName",
    "phoneNumber",
    "verified",
    "id",
    "yearOfStudy",
  ];
  for (let it of validUser) {
    if (user && !user[it]) {
      isValid = false;
      break;
    }
  }

  if (!user || user === "undefined" || !isValid) {
    return null;
  }
  return user;
};
const initialState = {
  user: validUser(),
  token: validToken(),
  isLogin: validToken() ? true : false,
  authLoading: false,
  authError: false,
  authErrorMessage: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state, action) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      state.user = null;
      state.token = null;
      state.isLogin = false;
    },
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.authLoading = true;
    },
    [login.fulfilled]: (state, action) => {
      state.authLoading = false;
      state.user = action.payload.user;
      state.token = action.payload.token;
      if (action.payload.token === "") {
        state.isLogin = false;
      } else {
        state.isLogin = true;
      }
      state.authError = false;
      state.authErrorMessage = "";
    },
    [login.rejected]: (state, action) => {
      state.authLoading = false;
      state.authError = true;
      state.authErrorMessage = action.payload;
    },
  },
});

export const { logout } = authSlice.actions;
export { login };
export default authSlice.reducer;
