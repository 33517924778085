import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import QuestionCard from "./QuestionCard";
import apiPro from "../../../api/apiPro";
import LinearProgress from "@mui/material/LinearProgress";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
function AddQuiz({ setUpdateLessonsAfterAdd }) {
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpen(false);
  };

  const handleAddQuiz = async () => {
    setLoadingAdd(true);
    try {
      await apiPro.post(`/months/${id}/lessons/quiz`, {
        title,
        questions,
      });
      swal("تم اضافة الاختبار بنجاح", {
        icon: "success",
        timer: 2000,
        buttons: false,
      });
      setLoadingAdd(false);
      setUpdateLessonsAfterAdd((prev) => prev + 1);
      setTitle("");
      setQuestions([]);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoadingAdd(false);
      swal(error.data, {
        icon: "error",
        timer: 2000,
        buttons: false,
      });
    }
  };

  useEffect(() => {
    if (questions.length > 0 && title !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [questions, title]);

  return (
    <Box>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        color="secondary"
        endIcon={<AddIcon />}
      >
        اضافة اختبار
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"md"}
        scroll={"paper"}
      >
        <DialogTitle textAlign={"center"}>اضافة اختبار</DialogTitle>
        {loadingAdd && <LinearProgress />}
        <DialogContent dividers>
          <Box
            sx={{
              margin: "0 auto",
              width: {
                xs: "100%",
                md: "50%",
              },
            }}
          >
            <TextField
              value={title}
              autoFocus
              margin="normal"
              id="title"
              label="عنوان الاختبار"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>
          {questions.map((question, index) => (
            <QuestionCard
              key={index}
              index={index}
              question={question}
              setQuestions={setQuestions}
              questions={questions}
            />
          ))}
          <Button
            endIcon={<AddIcon />}
            variant="outlined"
            color="secondary"
            onClick={() => {
              setQuestions((prev) => [
                ...prev,
                {
                  question: "",
                  options: [],
                  correctAnswer: 0,
                },
              ]);
            }}
            sx={{
              width: "100%",
              margin: "20px 0 0 0",
            }}
          >
            اضافة سؤال
          </Button>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            الغاء
          </Button>
          <Button
            disabled={disabled}
            variant="contained"
            color="secondary"
            onClick={handleAddQuiz}
          >
            اضافة الاختبار
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AddQuiz;
